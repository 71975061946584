<template>
  <section>
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="info.banners[0]"
        :src="info.banners[0].img"
        class="ctk-top-banner"
        >
          <v-layout wrap hidden-sm-and-down>
            <v-flex md6></v-flex>
            <v-flex md6 class="banner-right-div">
              <div class="banner-text-div">
                <p class="banner-text-en">CONTACT US</p>
                <div class="banner-text-line"></div>
                <p class="banner-text-zh">联系我们</p>
              </div>
            </v-flex>
          </v-layout>
        </v-img>
    </v-flex>
    <div class="div-1280 top-breadcrumb">
      <v-layout wrap>
        <v-flex md6 class="breadcrumb-left hidden-sm-and-down">联系我们</v-flex>
        <v-flex md6><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-flex>
      </v-layout>
    </div>
    <div class="div-1280 ctk-container">
      <div class="ctk-page-title wow fadeInUp">留言咨询</div>
      <div><img class="img-1280" src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/contact/1280.png"/></div>
      <div class="zx-subtitle">申请提交后，您将获得：</div>
      <v-layout wrap>
        <v-flex xs6 md4 v-for="item in items" :key="item.key" :class="'zx-flex zx-flex' + item.key%3">
          <v-card flat tile class="zx-card" >
            <div>
              <div class="zx-icon wow fadeInUp"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/contact/zx' + item.key + '.png'"/></div>
              <div class="zx-title wow fadeInUp" data-wow-delay="0.5s">{{item.title}}</div>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <div class="msg-banner ctk-container" id="msg">
      <div class="div-1280 ctk-container">
        <v-layout wrap>
          <v-flex xs12 md6 class="form-flex">
            <div class="form-div">
              <v-form
                v-model="messageForm"
                ref="messageForm"
                style="width:100%"
              >
                <v-row no-gutters>
                  <v-col cols="4" xs="4" md="3">
                    <v-subheader class="ctk-input-label">姓名:</v-subheader>
                  </v-col>
                  <v-col cols="8" xs="8" md="9">
                    <v-text-field height="36" outlined name="name" placeholder="请输入您的姓名" label="" :rules="[v => !!v || '请输入姓名']" v-model="formData.name" ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4"  xs="4" md="3">
                    <v-subheader class="ctk-input-label">手机号:</v-subheader>
                  </v-col>
                  <v-col cols="8" xs="8" md="9">
                    <v-text-field height="36" name="mobile" placeholder="请输入您的手机号" outlined label="" :rules="[ v => !!v || '请输入手机号', v => /^[1][0-9]{10}$/.test(v) || '请输入正确的手机号']" v-model="formData.mobile" ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4"  xs="4" md="3">
                    <v-subheader class="ctk-input-label">验证码:</v-subheader>
                  </v-col>
                  <v-col cols="4"  xs="4" md="5">
                    <v-text-field height="36" name="code" placeholder="请输入验证码" outlined label="" :rules="[ v => !!v || '请输入验证码', v => /(^[\-0-9][0-9]*(.[0-9]+)?)$/.test(v) || '验证码错误']" v-model="formData.code" > </v-text-field>
                  </v-col>
                  <v-col cols="4"  xs="4" md="4">
                    <a id="send_code_btn" @click="sendCode">获取验证码</a>
                  </v-col>
                </v-row>
                <!-- <div class="code-div">
                  <div class="code-input-div">
                  </div>
                  <div class="code-btn-div">
                  </div>
                </div> -->
                <v-row no-gutters>
                  <v-col cols="4" xs="4" md="3">
                    <v-subheader class="ctk-input-label">留言:</v-subheader>
                  </v-col>
                  <v-col cols="8" xs="8" md="9">
                    <v-textarea dense name="content" outlined placeholder="请输入您的咨询内容，以便我们可以更好地为您提供服务" label="" rows="3" v-model="formData.content" ></v-textarea>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="4" xs="4" md="3"></v-col>
                  <v-col cols="8" xs="8" md="9">
                    <v-btn depressed tile color="primary" @click="submitForm" width="88">提交</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <div class="div-1280 ctk-container">
      <div class="ctk-page-title wow fadeInUp">请前往线下门店体验</div>
      <v-layout wrap v-if="stores">
        <v-flex xs12 md4 v-for="(item, i) in stores" :key="item.key" :class="'store-flex store-flex' + i%3">
          <v-card flat tile class="store-card" >
            <div>
              <div class="store-name wowd fadeInUp">{{item.name}}</div>
              <div class="store-addr wowd fadeInUp" data-wow-delay="0.2s">{{item.addr}}</div>
              <div class="store-img wowd fadeInUp" data-wow-delay="0.4s"><img :src="item.img"/></div>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
              <v-card-title class="headline">操作提示</v-card-title>
            <v-card-text>{{returnMsg}}</v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="dialog = false" >确定</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 6,
    innerWidth: window.innerWidth,
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' },
      { text: '联系我们', disabled: true }
    ],
    items: [
      { key: 1, title: '好睡眠资深顾问一对一沟通' },
      { key: 2, title: '预约“好睡眠”环境体验中心现场体验' },
      { key: 3, title: '家庭睡眠环境检测、安装测量' },
      { key: 4, title: '一对一定制睡眠环境改善解决方案' },
      { key: 5, title: '尊享“三无”产品*、“三无”服务*' },
      { key: 6, title: '终身售后服务咨询支持' },
      { key: 7, title: '享受好睡眠会员多项特权' },
      { key: 8, title: '加入好睡眠会员交流社群' }
    ],
    stores: null,
    messageForm: false,
    returnMsg: null,
    dialog: false,
    formData: {
      name: '',
      mobile: '',
      code: '',
      content: ''
    },
    btnDisable: false,
    itv: '',
    scd: 60
  }),
  created () {
    document.title = '联系我们|留言|门店-好睡眠'
    document.querySelector('#menu_contact').classList.add('v-btn--active')
  },
  mounted () {
    let hash = location.hash.slice(1)
    if (hash) {
      this.goAnchor(hash)
    }
    this.getStore()
    this.$wow.init()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    goAnchor (selector) {
      let el = document.getElementById(selector)
      if (el) {
        el.scrollIntoView(true)
      }
    },
    getStore () {
      this.https.get('store', { city: this.param }).then(response => {
        if (response.code === 0 && response.data.store) {
          this.stores = response.data.store
        }
      }).then(_ => {
        this.$wowd.init()
      })
    },
    sendCode () {
      if (this.btnDisable) {
        return false
      }
      if (/^[1][0-9]{10}$/.test(this.formData.mobile)) {
        this.btnDisable = true
        document.getElementById('send_code_btn').innerText = '发送中...'
        var that = this
        this.https.get('join_code', { mobile: this.formData.mobile }).then(response => {
          if (response.code === 0) {
            that.itv = setInterval(function () {
              if (that.scd > 1) {
                that.scd -= 1
                document.getElementById('send_code_btn').innerText = '等待' + that.scd + '(s)'
              } else {
                that.btnDisable = false
                document.getElementById('send_code_btn').innerText = '发送验证码'
                that.scd = 60
                clearInterval(that.itv)
              }
            }, 1000)
          } else {
            that.btnDisable = false
            document.getElementById('send_code_btn').innerText = '发送验证码'
            that.scd = 60
            this.returnMsg = response.msg
            this.dialog = true
          }
        })
      } else {
        this.returnMsg = '请输入正确的手机号码'
        this.dialog = true
      }
    },
    submitForm () {
      this.$refs.messageForm.validate()
      if (this.messageForm === true) {
        this.https.get('online_message', this.formData).then(response => {
          if (response.code === 0) {
            this.$refs.messageForm.reset()
            document.getElementById('send_code_btn').innerText = '发送验证码'
          }
          this.returnMsg = response.msg
          this.dialog = true
        })
      }
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/contact.css';
</style>
